import { createRouter, createWebHashHistory } from 'vue-router';
//import Page from '../views/Page.vue';
/*import NewsPage from '../views/NewsPage';
import ArticlePage from '../views/ArticlePage';
import FaqPage from '../views/FaqPage';
import QuickLink from '../views/QuickLink';
import Highlights from '../views/Highlights';
import Philosophy from '../views/Philosophy';
import Admin from '../views/Admin'*/

// 2. Define some routes
const routes = [
  {
    path: "/",
    beforeEnter() {
      window.location.href = "https://www.snclavalin.com/en/markets-and-services/markets/energy/nuclear";
    }
  },
  {
    path: "/updates",
    beforeEnter() {
      window.location.href = "https://www.snclavalin.com/en/markets-and-services/markets/energy/nuclear";
    }
  },
  {
    path: "/faqs",
    beforeEnter() {
      window.location.href = "https://www.snclavalin.com/en/markets-and-services/markets/energy/nuclear";
    }
  },
  {
    path: "/quick-link",
    beforeEnter() {
      window.location.href = "https://www.snclavalin.com/en/markets-and-services/markets/energy/nuclear";
    }
  },
  {
    path: "/highlights",
    beforeEnter() {
      window.location.href = "https://www.snclavalin.com/en/markets-and-services/markets/energy/nuclear";
    }
  },
  {
    path: "/our-philosophy",
    beforeEnter() {
      window.location.href = "https://www.snclavalin.com/en/markets-and-services/markets/energy/nuclear";
    }
  }
/*  { path: '/', redirect: '/updates' },
  { path: '/:slug', component: Page },
  { path: '/updates', component: NewsPage },
  { path: '/updates/:slug', component: ArticlePage },
  { path: '/faqs', component: FaqPage },
  { path: '/quick-link', component: QuickLink },
  { path: '/highlights', component: Highlights },
  { path: '/our-philosophy', component: Philosophy },
  { path: '/admin', component:Admin},*/
];

// 3. Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;