import { createApp, provide, h } from 'vue';
import App from './App.vue';
import router from './router/index';
import { DefaultApolloClient } from '@vue/apollo-composable';
import apolloClient from './Apollo/apolloClient';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import VueGtag from "vue-gtag";



createApp({

  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App)
}).use(router).use(VueGtag, {
  disableScriptLoad: true,
  config: { 
    id: "G-Q96W71DDP8",
  },
  pageTrackerTemplate(to){
    return{
      page_title:to.path,
      page_path:to.fullPath
    }
  }
},router).mount('#app');
